.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  max-width: 95vw;
  max-height: 80vh;
  padding: 15px 20px;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 0.75);
  border: 3px solid #f6e87e;

  @media (orientation: portrait) {
    transform: rotate(90deg) translate(-50%, -50%);
    transform-origin: top left;
    max-width: 95vh;
    max-height: 80vw;
  }

  &__title {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
    font-weight: bolder;
    text-shadow: 0px 0px 1px #ae8f27, 0px 0px 1px #ae8f27, 0px 0px 1px #ae8f27, 0px 0px 1px #ae8f27;
  }
  .popup__close {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
  }

  &_menu {
    flex-direction: column;
  }
}

.loading {
  transform: rotate(0) translate(-50%, -50%);
  max-width: 95vw;
  max-height: 80vh;
}