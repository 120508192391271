@import '../../assets/styles/colors';
@import '../../assets/styles/variables';

.canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  canvas {
    width: 100%;
    height: 100%;
  }
}

.toast {
  @media (orientation: portrait) {
    transform: rotate(90deg);
    top: 115px !important;
    right: 15px !important;
    margin-right: -100px !important;
    left: auto !important;
  }
}