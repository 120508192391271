@import '../../assets/styles/colors';
@import '../../assets/styles/animations';

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.5vh;

  .companyLogo {
    width: auto;
    height: auto;
    max-height: 50vh;
    max-width: 100%;
    margin-bottom: 9px;
  }
}

.loadScreenWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: $black;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.progressBar {
  width: 100%;
  max-width: 500px;
  height: 4vh;
  max-height: 20px;
  margin: 0 auto;
  border: 1px solid #b5a513;
  border-radius: 12px;
  box-shadow: 0 0 3px 1px #b5a513;
  overflow: hidden;

  svg {
    height: 100%;
  }
}
