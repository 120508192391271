.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    text-shadow: 0 0 0 rgba(#b5a513, 0);
  }

  70% {
    transform: scale(1);
    text-shadow: 0 0 6px rgba(#b5a513, 1);
  }

  100% {
    transform: scale(0.95);
    text-shadow: 0 0 0 rgba(#b5a513, 0);
  }
}

.isFadeEffect {
  animation: isFadeEffect 4s;
}

@keyframes isFadeEffect {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
