 .banner{
   position:fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   z-index: 10000;
   background-color: #000000;
   display: none;

   @media (orientation: portrait) {
      display: flex;
      justify-content: center;
      align-items: center;
   }

    .banner-description{
       display: flex;
       flex-direction: column;
       justify-content: center;
       color: #ffffff;
       font-size: 1.5rem;
       max-width: 75%;
       text-align: center;
       img{
          margin-bottom: 25px
       }
    }
 }